<script lang="ts">import { Router } from "@roxi/routify";
import { routes } from "../.routify/routes";
</script>

<style global>/* JOST* */

/*@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-100-Hairline.ttf") format("truetype"),
		url("/fonts/Jost-100-Hairline.otf") format("opentype");
	font-weight: 100;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-200-Thin.ttf") format("truetype"),
		url("/fonts/Jost-200-Thin.otf") format("opentype");
	font-weight: 200;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-300-Light.ttf") format("truetype"),
		url("/fonts/Jost-300-Light.otf") format("opentype");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}*/

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-400-Book.ttf") format("truetype"),
		url("/fonts/Jost-400-Book.otf") format("opentype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-500-Medium.ttf") format("truetype"),
		url("/fonts/Jost-500-Medium.otf") format("opentype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-600-Semi.ttf") format("truetype"),
		url("/fonts/Jost-600-Semi.otf") format("opentype");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-700-Bold.ttf") format("truetype"),
		url("/fonts/Jost-700-Bold.otf") format("opentype");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-800-Heavy.ttf") format("truetype"),
		url("/fonts/Jost-800-Heavy.otf") format("opentype");
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-900-Black.ttf") format("truetype"),
		url("/fonts/Jost-900-Black.otf") format("opentype");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

/*@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-100-Hairline-Italic.ttf") format("truetype"),
		url("/fonts/Jost-100-Hairline-Italic.ttf") format("opentype");
	font-weight: 100;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-200-ThinItalic.ttf") format("truetype"),
		url("/fonts/Jost-200-ThinItalic.ttf") format("opentype");
	font-weight: 200;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-300-LightItalic.ttf") format("truetype"),
		url("/fonts/Jost-300-LightItalic.ttf") format("opentype");
	font-weight: 300;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-400-BookItalic.ttf") format("truetype"),
		url("/fonts/Jost-400-BookItalic.ttf") format("opentype");
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-500-MediumItalic.ttf") format("truetype"),
		url("/fonts/Jost-500-MediumItalic.ttf") format("opentype");
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-600-SemiItalic.ttf") format("truetype"),
		url("/fonts/Jost-600-SemiItalic.ttf") format("opentype");
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-700-BoldItalic.ttf") format("truetype"),
		url("/fonts/Jost-700-BoldItalic.ttf") format("opentype");
	font-weight: 700;
	font-style: italic;

}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-800-HeavyItalic.ttf") format("truetype"),
		url("/fonts/Jost-800-HeavyItalic.ttf") format("opentype");
	font-weight: 800;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "Jost";
	src:
		local("Jost"),
		url("/fonts/Jost-900-BlackItalic.ttf") format("truetype"),
		url("/fonts/Jost-900-BlackItalic.ttf") format("opentype");
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}
*/

@font-face {
	font-family: "EB Garamond";
	src:
		local("EB Garamond"),
		url("/fonts/EBGaramond-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "EB Garamond";
	src:
		local("EB Garamond"),
		url("/fonts/EBGaramond-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

/*@font-face {
	font-family: "EB Garamond";
	src:
		local("EB Garamond"),
		url("/fonts/EBGaramond-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "EB Garamond";
	src:
		local("EB Garamond"),
		url("/fonts/EBGaramond-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "EB Garamond";
	src:
		local("EB Garamond"),
		url("/fonts/EBGaramond-ExtraBold.ttf") format("truetype");
	font-weight: 900;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "EB Garamond";
	src:
		local("EB Garamond"),
		url("/fonts/EBGaramond-RegularItalic.ttf") format("truetype");
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "EB Garamond";
	src:
		local("EB Garamond"),
		url("/fonts/EBGaramond-MediumItalic.ttf") format("truetype");
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "EB Garamond";
	src:
		local("EB Garamond"),
		url("/fonts/EBGaramond-SemiBoldItalic.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: "EB Garamond";
	src:
		local("EB Garamond"),
		url("/fonts/EBGaramond-BoldItalic.ttf") format("truetype");
	font-weight: 700;
	font-style: italic;

}

@font-face {
	font-family: "EB Garamond";
	src:
		local("EB Garamond"),
		url("/fonts/EBGaramond-ExtraBoldItalic.ttf") format("truetype");
	font-weight: 900;
	font-style: italic;
	font-display: swap;
}
*/

:global(*) {
	box-sizing: inherit;
	color: inherit;
	font: inherit;
	padding: 0;
	margin: 0;
	gap: inherit;
}

:global(:root) {
	box-sizing: border-box;

	--celo-green: #35D07F;
	--celo-gold: #FBCC5C;
	--celo-dark: #2E3338;
	--celo-white: #FFFFFF;

	--celo-violet: #BF97FF;
	--celo-red: #FB7C6D;
	--celo-cyan: #73DDFF;
	--celo-blue: #3488EC;

	--celo-heavy-gray: #ABADAF;
	--celo-gray: #DDDDDD;
	--celo-light-gray: #EDEEEF;
	--celo-faint-gray: #F8F9F9;
	--celo-faint-gold: #FEF2D6;


	--body-font: Jost, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	--heading-font: EB Garamond, eb-garamond, Garamond, var(--body-font);

	--background-color: var(--celo-light-gray);

	background-color: var(--background-color);
	color: var(--celo-dark);

	font-family: var(--body-font);
	font-size: 16px;
}

:global(html), :global(body) {
	position: relative;
	width: 100%;
	height: 100%;

	overflow: hidden auto;
}

/* Mobile Webkit 100vh hack */

/* html {
	height: -webkit-fill-available;
}
body {
	min-height: 100vh;
	min-height: -webkit-fill-available;
} */

:global(h1) {
	font-family: var(--heading-font);
	font-size: 3em;
	font-weight: 500;
}

:global(h2) {
	font-family: var(--heading-font);
	font-size: 2.5em;
}

:global(h3) {
	font-size: 1.5em;
	line-height: 1.2;
}

:global(h4) {
	font-family: var(--heading-font);
	font-size: 1.75em;
}

:global(h5) {
	font-size: 1.25em;
}

:global(strong) {
	font-weight: bold;
}

:global(small) {
	font-size: smaller;
}

:global(a) {
	color: var(--celo-green);
	text-decoration: none;
	transition: 0.3s;
}

:global(a:hover) {
	text-decoration: underline;
}

:global(img) {
	transition: 0.3s;
}

:global(a:hover) :global(img) {
	/* filter: saturate(1.05) contrast(0.95) brightness(1.05);
	filter: saturate(1.5) contrast(0.95) brightness(1.05); */
	filter: saturate(1.33);
	opacity: 0.85;
}

:global(label) {
	display: block;
}

:global(input[type="text"]), :global(input[type="search"]), :global(button), :global(textarea) {
	background-color: var(--celo-white);
	box-shadow: 0 1px var(--depth) rgba(var(--celo-dark), 0.5);
	border: none;
	border-radius: 0.5em;
	padding: 0.75em 1em;
	-webkit-appearance: none;
	   -moz-appearance: none;
	        appearance: none;
}

:global(select) {
	background: none;
	border: none;
	cursor: pointer;
}

:global(input:disabled) {
	color: #ccc;
}

:global(input[type="range"]) {
	height: 0;
}

:global(button) {
	color: var(--celo-dark);
	background-color: var(--celo-green);
	outline: none;
	cursor: pointer;
	padding: 0.5em 1em;
	transition: 0.3s;
}

:global(button:hover) {
	background-color: var(--celo-gold);
}

:global(button:disabled) {
	filter: grayscale();
}

:global(button:not(:disabled):active) {
	background-color: #ddd;
}

:global(button:focus) {
	border-color: #666;
}

:global(.card) {
	--depth: 5px;
	--y: 0;

	background-color: var(--celo-white);
	box-shadow: 0 var(--depth) calc(1px + var(--depth)) rgba(0, 0, 0, 0.06);
	border-radius: 1.5em;
	padding: 2em;

	transition: 0.25s;

	display: grid;
	gap: 1.25em;

	transform: translateY(var(--y));
}

:global(.card.static) {
	box-shadow: none;
}

:global(.card:not(.static):hover), :global(.card:not(.static):focus) {
	--depth: 10px;
	--y: -3px;
}

:global(.card:not(.static):active) {
	transition: 0.05s;
	--depth: 1px;
	--y: 1px;
}

:global(a.card) {
	color: inherit;
	text-decoration: none;
}

:global(img) {
	max-width: 100%;
	-o-object-fit: contain;
	   object-fit: contain;
}

:global(hr) {
	background: none;
	border: none;
	border-bottom: 1px solid;
	opacity: 0.15;
}

:global(iframe) {
	border: none;
	width: 100%;
	aspect-ratio: 16/9;
	-o-object-fit: contain;
	   object-fit: contain;
}

:global(.full) {
	grid-column: 1 / -1;
}

:global(.row) {
	display: grid;
	grid-auto-flow: column;
}

:global(.bar) {
	display: grid;
	/* grid-template-columns: minmax(0, 1fr); */
	grid-auto-flow: column;
	align-items: center;
	justify-content: space-between;
}

:global(.sticky) {
	position: sticky;
	top: 0;
	z-index: 1;
	-webkit-backdrop-filter: blur(20px);
	        backdrop-filter: blur(20px);
}

:global(.sticky:before) {
	content: '';
	position: absolute;
	inset: 0;
	left: 0; right: 0; top: 0; bottom: 0;
	z-index: -1;
	background-color: var(--background-color);
	opacity: 0.85;
}

:global(.grid) {
	--column-size: calc(16em + 0.2vw);

	display: grid;
	grid-template-columns: repeat(auto-fill, [col] minmax(var(--column-size), 1fr));
	gap: var(--space-inner);
	align-content: start;
}

:global(.grid.small) {
	--column-size: calc(11em + 0.15vw);
}

/* @media (max-width: 38rem) { */

/* .grid {
		grid-template-columns: minmax(0, 1fr);
		grid-auto-flow: row;
	} */

/* .grid > * {
		grid-column: 1 / -1;
	}
} */</style>

<Router {routes} />
