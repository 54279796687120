<!-- routify:options index=false --> 

<h3>Download Valora</h3>
<article class="project card static">
	<img src="/images/thumbnails/Valora.png" alt="Valora Thumbnail" width="250" />
	<p>
		<a href="https://raw.githubusercontent.com/Open-C/CeloHub/main/assets/Valora1.14.2.apk" download>
			<button><img src="/images/link-icons/Android-Logo-Round@3x.png" alt="Android Icon" style="height: 1.6em; vertical-align: -0.45em" /> Download Android APK</button>
		</a>
	</p>
</article>
<p>
	<a href="https://www.androidauthority.com/how-to-install-apks-31494/">Learn how to install Android APK files ›</a>
</p>


<style>
	.project {
		justify-self: start;
		text-align: center;
	}</style>